// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-about-tsx": () => import("./../src/pages/about/About.tsx" /* webpackChunkName: "component---src-pages-about-about-tsx" */),
  "component---src-pages-about-index-fr-ts": () => import("./../src/pages/about/index.fr.ts" /* webpackChunkName: "component---src-pages-about-index-fr-ts" */),
  "component---src-pages-about-index-ts": () => import("./../src/pages/about/index.ts" /* webpackChunkName: "component---src-pages-about-index-ts" */),
  "component---src-pages-contact-contact-tsx": () => import("./../src/pages/contact/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-contact-tsx" */),
  "component---src-pages-contact-index-fr-ts": () => import("./../src/pages/contact/index.fr.ts" /* webpackChunkName: "component---src-pages-contact-index-fr-ts" */),
  "component---src-pages-contact-index-ts": () => import("./../src/pages/contact/index.ts" /* webpackChunkName: "component---src-pages-contact-index-ts" */),
  "component---src-pages-events-events-tsx": () => import("./../src/pages/events/Events.tsx" /* webpackChunkName: "component---src-pages-events-events-tsx" */),
  "component---src-pages-events-index-fr-ts": () => import("./../src/pages/events/index.fr.ts" /* webpackChunkName: "component---src-pages-events-index-fr-ts" */),
  "component---src-pages-events-index-ts": () => import("./../src/pages/events/index.ts" /* webpackChunkName: "component---src-pages-events-index-ts" */),
  "component---src-pages-home-tsx": () => import("./../src/pages/Home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-fr-ts": () => import("./../src/pages/index.fr.ts" /* webpackChunkName: "component---src-pages-index-fr-ts" */),
  "component---src-pages-index-ts": () => import("./../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-our-coffee-index-fr-ts": () => import("./../src/pages/our-coffee/index.fr.ts" /* webpackChunkName: "component---src-pages-our-coffee-index-fr-ts" */),
  "component---src-pages-our-coffee-index-ts": () => import("./../src/pages/our-coffee/index.ts" /* webpackChunkName: "component---src-pages-our-coffee-index-ts" */),
  "component---src-pages-our-coffee-our-coffee-tsx": () => import("./../src/pages/our-coffee/OurCoffee.tsx" /* webpackChunkName: "component---src-pages-our-coffee-our-coffee-tsx" */),
  "component---src-pages-success-index-fr-ts": () => import("./../src/pages/success/index.fr.ts" /* webpackChunkName: "component---src-pages-success-index-fr-ts" */),
  "component---src-pages-success-index-ts": () => import("./../src/pages/success/index.ts" /* webpackChunkName: "component---src-pages-success-index-ts" */),
  "component---src-pages-success-success-tsx": () => import("./../src/pages/success/Success.tsx" /* webpackChunkName: "component---src-pages-success-success-tsx" */)
}

